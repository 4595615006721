import React, { useContext } from 'react';
import { Preview } from 'ui/components/4-habitats/Preview';

/**
 * This interface is a bit weird, but can be everything from documentType 'homePage', 'segmentPage', 'contentPage' og 'articlePage'.
 * All these pages do not share the exact same properties.
 */
export interface PageContextPage extends Omit<Content.PageContent, 'page'> {
	page: Partial<
		Omit<Content.Page, 'properties'> & {
			properties?: Content.BasePage &
				Content.MetaData &
				Content.HeroHeading &
				Content.ArticlePageContent &
				Content.PageHeaders;
		}
	>;
}
export class PageContextProps {
	private _pageContent: PageContextPage;

	public constructor(pageContent: PageContextPage) {
		this._pageContent = pageContent;
	}

	public get template(): string {
		return this._pageContent?.page?.template;
	}

	public get documentType(): string {
		return this._pageContent?.page?.documentType;
	}

	public get name(): string {
		return this._pageContent?.page?.name;
	}

	public get settings(): Content.Page['properties']['pageSettings'] {
		return this._pageContent?.page?.properties?.pageSettings;
	}

	public get id(): number {
		return this._pageContent?.page?.id;
	}

	public get url(): string {
		return this._pageContent?.page?.url;
	}

	public get content(): Partial<
		Content.BasePage & Content.MetaData & Content.HeroHeading & Content.ArticlePageContent & Content.PageHeaders
	> {
		return this._pageContent?.page?.properties;
	}

	public get footer(): Content.PageContent['footer'] {
		return this._pageContent?.footer;
	}

	public get preview(): boolean {
		return this._pageContent?.preview ?? false;
	}

	public get previewData(): Models.PreviewData {
		return this._pageContent?.previewData;
	}

	public get themeSettings(): Models.Settings.ThemeSettings {
		return this._pageContent?.themeSettings;
	}

	public getSettings(key: 'navigation'): Content.Navigation['content']['properties'];
	public getSettings(key: 'openGraph'): Content.OpenGraph['content']['properties'];
	public getSettings(key: 'pageSectionsTopSpacing'): Content.PageSectionsTopSpacing['content']['properties'];
	public getSettings(
		key: 'navigation' | 'openGraph' | 'pageSectionsTopSpacing',
	): (Content.Navigation | Content.OpenGraph | Content.PageSectionsTopSpacing)['content']['properties'] {
		const setting = this.settings?.find((setting) => setting.documentType === key);
		return setting?.content?.properties;
	}
}

export const PageContext = React.createContext<Partial<PageContextProps>>(new PageContextProps(null));

export interface PageContextProviderProps {
	content: PageContextPage;
	children?: React.ReactNode;
}
export const PageContextProvider: React.FC<PageContextProviderProps> = ({ content, children }) => {
	const pageContext: PageContextProps = new PageContextProps(content);

	return (
		<PageContext.Provider value={pageContext}>
			<Preview url={content?.page?.url}>{children}</Preview>
		</PageContext.Provider>
	);
};

export function usePageContext(): Partial<PageContextProps> {
	return useContext(PageContext);
}
