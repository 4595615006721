import { nullCheck } from 'helpers/functionNotNull/functionNotNull';
import { stripAndSplitUrl } from 'helpers/string/string';
//import { WEBSITE_INTERNAL_PROTOCOL } from '_constant';

export type BaseNextUrl = {
	host: string;
	protocol: string;
};

/** Make sure that the returned hostname comes with correct protocol and port. */
export const requestHostHelper = (nextUrl: BaseNextUrl, host?: string): string => {
	// We seriously prefer that host is present and we do not have to fallback to what ever the nextUrl is giving us.
	// It is not reliable but better than nothing.
	const hostname = host ?? nextUrl.host;

	// Add slashes if not present already
	const slashes = hostname.includes('//') ? '' : '//';

	// We always run http, locally, as the IIS and firewall do the SSL off loading
	return `http:${slashes}${hostname}`;
};

export type ConfigSiteBase = { theme: Models.Settings.SiteTheme; title: string };
export type ConfigSite = Omit<ConfigSiteBase, 'theme'> & { hostname: string; theme: string };

export const hostMapHelper = (hostname: string, sites: ConfigSite[], fallback: ConfigSiteBase): ConfigSiteBase => {
	const result = sites.find((elm) => stripAndSplitUrl(elm.hostname) === stripAndSplitUrl(hostname));
	if (result === undefined || result === null) {
		// Treeshaking issue: Dynamic code evaluation - cannot ues logger lib her
		/* eslint-disable no-console */
		console.warn(
			`hostMapHelper() could not match hostname to correct site. Returns fallback site. Providet hostname: ${hostname} `,
		);
		return fallback;
	}
	return { ...result, theme: result.theme.toLocaleLowerCase() as Models.Settings.SiteTheme };
};

/**
 * Checks if the hostname is a valid in regards of calling the CMS.
 * Often we see requests with host http://[::1]/ which can never succeed so no need to disturb the CMS then.
 * @param host
 * @returns false if the host is null, undefined or contains '['
 */
export const validateHost = (host: string): boolean => {
	if (nullCheck(host)) {
		return !host.includes('[');
	}
	return false;
};
