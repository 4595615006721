import { queryCmsContent } from './_api';
import { CONTENT_API_URL } from './_constants';
import logger from 'helpers/logger';
import { minimalError } from 'helpers/error/minimalError';

export interface NavigationAdapterRequestInterface {
	host: string | string[];
	preview: boolean;
	previewData: Models.PreviewData;
}

export interface NavigationAdapterResponseInterface {
	properties: Record<string, unknown>;
	children: Array<NavigationAdapterResponseInterface>;
	id: number;
	culture: string;
	name: string;
	url: string;
	documentType: string;
	template: string;
	createDate: string;
	sortOrder: number;
}

export const getCmsNavigation = async ({
	host,
	preview,
	previewData,
}: NavigationAdapterRequestInterface): Promise<Navigation.NavigationItem> => {
	if (!CONTENT_API_URL) {
		logger.error('Content api url is not defined');
	}

	const path = '/';
	const method = 'POST';
	const query = `{
		navigation: navigation(path: "${path}" maxDepth: "10")
	}`;

	return await queryCmsContent<{
		navigation: Navigation.NavigationItem;
	}>({ host, method, query, preview, previewData, redirectAble: false })
		.then((result) => {
			return result.navigation;
		})
		.catch((err) => {
			logger.error('getCmsNavigation(): query cms for navigation failed.', minimalError(err));
			throw err;
		});
};
