import styles from './Preview.module.scss';
import { Link } from 'ui/components/1-atoms/Action/Link';
import { useRouter } from 'next/router';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export interface PreviewProps {
	url: string;
	children?: React.ReactNode;
}

export const Preview: React.FC<PreviewProps> = ({ children, url }) => {
	const { isPreview } = useRouter();
	const dictionary = useDictionaryContext();

	if (!isPreview) return <>{children}</>;

	return (
		<div className={styles.Preview}>
			<div className={styles.Preview_top}>
				<p>
					{dictionary.getValue('Preview.InPreview')}{' '}
					<Link className={styles.Preview_link} url={`/api/exitPreview?slug=${url}`}>
						{dictionary.getValue('Preview.ExitPreview')}
					</Link>
				</p>
			</div>
			<div className={styles.Preview_content}>{children}</div>
		</div>
	);
};
