export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Removes Https:// or http:// from url. Optionally also a given root address. Furthermore, if applied url ends with a '/'.  this will be removed
 * @param url Full url that need to be manipulated. Ex 'https://pentia.dk/services/selvbetjeningslosninger-og-portaler/'
 * @param root optional root url. Ex 'pentia.dk/'
 * @returns string
 */
export const removeHttp = (url: string, root = ''): string => {
	const https = 'https://' + root;
	const http = 'http://' + root;

	let result = url;

	if (url?.startsWith(https)) {
		result = url.slice(https.length);
	}

	if (url?.startsWith(http)) {
		result = url.slice(http.length);
	}

	if (result?.endsWith('/')) {
		return result.substring(0, result.length - 1);
	}

	return result;
};

/**
 * Strips a url for http/https, www and everything after /
 * @param url. Ex. https://www.mst.dk/nyheder
 * @returns mst.dk
 */
export const stripAndSplitUrl = (url: string): string => {
	const res = replacePartOfString(removeHttp(url), 'www.', '');
	return res.split('/')[0];
};

export const replacePartOfString = (baseText: string, stringToReplace: string, replacementText: string): string => {
	return baseText.replace(stringToReplace, replacementText ?? '');
};

/** Taken from https://codingbeautydev.com/blog/javascript-remove-special-characters-from-string/ */
export const removeSpecialCharactersAndTrim = (stringToReplace: string): string => {
	return stringToReplace.replace(/([^\wøæå ]|_)/g, '').trim();
};

/** From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent#decoding_query_parameters_from_a_url */
export const decodeQueryParamString = (stringToDecode: string): string => {
	return decodeURIComponent(stringToDecode.replace(/\+/g, ' '));
};

export const insertShy = (string: string): string => (string ? string.split('|').join('\u00AD') : '');
export const removePipe = (string: string): string => (string ? string.replace('|', '') : '');

export const handleShyHeading = (texts: Content.HeroHeading): string => {
	const { heading, shyHeading } = texts ?? {};
	return insertShy(shyHeading && shyHeading.length > 0 ? shyHeading : heading ?? '');
};
