import { get } from 'env-var';

let CONTENT_API_URL: string;
let NEXT_PUBLIC_BASE_SELECTEDFILES_API_URL: string;
let NEXT_PUBLIC_CONTENT_API_URL: string;
let NEXT_PUBLIC_SEARCH_API_URL: string;
// Only perform assignment and check serverside / at build time
if (typeof window === 'undefined') {
	// Read variables using env-var API
	CONTENT_API_URL = get('CONTENT_API_URL').required().asUrlString();
	NEXT_PUBLIC_CONTENT_API_URL = get('NEXT_PUBLIC_CONTENT_API_URL').required().asUrlString();
	NEXT_PUBLIC_SEARCH_API_URL = get('NEXT_PUBLIC_SEARCH_API_URL').required().asUrlString();
	NEXT_PUBLIC_BASE_SELECTEDFILES_API_URL = get('NEXT_PUBLIC_BASE_SELECTEDFILES_API_URL').required().asUrlString();
}

export {
	CONTENT_API_URL,
	NEXT_PUBLIC_BASE_SELECTEDFILES_API_URL,
	NEXT_PUBLIC_CONTENT_API_URL,
	NEXT_PUBLIC_SEARCH_API_URL,
};
