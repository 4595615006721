import logger from 'helpers/logger';

export const getUrlPathname = (url: string, baseUrl: string): string => {
	try {
		try {
			const urlObject = new URL(url, baseUrl);
			return urlObject.pathname;
		} catch (error: unknown) {
			// If the baseUrl is not valid, we try to get the pathname from the url only
			const urlObject = new URL(url);
			return urlObject.pathname;
		}
	} catch (error: unknown) {
		// If the pathname is still not valid, we log the error and return the original url

		logger.error(`helpers/getUrlPathname() - Failed to retrieve pathname from URL: ${url}`, error);
		return url;
	}
};

/** Takes original path and host and combines these to a valid url and compares this to the redirectDestination
 * @param originalPath
 * @param originalHost
 * @param redirectDestination. Must be a url.
 * @returns true if urls are equal
 */
export const urlsAreEqual = (originalPath: string, originalHost: string, redirectDestination: string): boolean => {
	let path = originalPath?.startsWith('/') ? originalPath : `/${originalPath}`;
	path = path?.endsWith('/') ? path.slice(0, -1) : path;

	const host = originalHost?.endsWith('/') ? originalHost.slice(0, -1) : originalHost;
	const origDest = host + path;
	const newDest = redirectDestination?.endsWith('/') ? redirectDestination.slice(0, -1) : redirectDestination;

	return origDest === newDest;
};
