import { DictionaryContextProvider } from 'application/contexts/DictionaryContext';
import { NavigationContextProvider } from 'application/contexts/NavigationContext';
import { PageContextProvider } from 'application/contexts/PageContext';
import { PersistGate } from 'redux-persist/integration/react';
import { SiteContextProvider } from 'application/contexts/SiteContext';
import { NextPage } from 'next';
import type { AppProps as NextAppProps } from 'next/app';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import 'ui/styles/globals.scss';
import { store } from '../application/adapters/store/store';

export type DXPContentPage<TProps extends { content?: Content.PageContent }> = NextPage<
	TProps['content'] & Omit<TProps, 'content' | 'session'>
>;

const App: React.FC<NextAppProps> = ({ Component, pageProps }) => {
	const { content, dictionary, navigation, site } = pageProps ?? {};
	const { page } = content ?? {};
	const { culture } = page ?? {};

	const persistor = persistStore(store);

	return (
		<StrictMode>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<SiteContextProvider site={site}>
						<DictionaryContextProvider dictionary={dictionary ?? []} culture={culture}>
							<PageContextProvider content={content}>
								<NavigationContextProvider navigation={navigation}>
									<Component {...content} {...pageProps} />
								</NavigationContextProvider>
							</PageContextProvider>
						</DictionaryContextProvider>
					</SiteContextProvider>
				</PersistGate>
			</Provider>
		</StrictMode>
	);
};

export default App;
