import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = '';

export const segmentSlice = createSlice({
	name: 'segment',
	initialState,
	reducers: {
		setSegment: (state, action: PayloadAction<string>) => {
			if (action.payload) {
				return action.payload;
			} else {
				return state;
			}
		},
	},
});

export const segment: (state: RootState) => string = (state: RootState) => state.segment;

export const { setSegment } = segmentSlice.actions;
export default segmentSlice.reducer;
