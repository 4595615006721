import winston from 'winston';
import { generateId } from './id';
import { nullCheck } from './functionNotNull/functionNotNull';

/** Standardized set of functions/methods for logging */

interface Logger {
	internalLogger: winston.Logger | null;
	loggerLoaded(): boolean;
	setInternalLogger(logger: unknown): winston.Logger | null;
	info(message: string, ...args: unknown[]): void;
	warn(message: string, ...args: unknown[]): void;
	debug(message: string, ...args: unknown[]): void;
	error(message: string, ...args: unknown[]): void;
	startTimer(): { done: (message: unknown) => void };
}

const prefixMessage = (message: string) => {
	return `${message}`;
};

/* eslint-disable no-console */
const logger: Logger = {
	internalLogger: null,
	loggerLoaded: () => {
		return nullCheck(logger.internalLogger);
	},

	setInternalLogger(logger: unknown): winston.Logger | null {
		this.internalLogger = logger;
		return this.internalLogger;
	},

	/**
	 * Logs information
	 *
	 * @param message The message to log
	 */
	info: (message: string, ...args: unknown[]): void => {
		if (logger.loggerLoaded()) {
			logger.internalLogger.info(prefixMessage(message), ...args);
			return;
		}

		// Possible to add styling to browser's default console object here
		if (process.env.NEXT_PUBLIC_ALLOW_BROWSER_CONSOLE === 'true') console.info(prefixMessage(message), ...args);
	},

	/**
	 * Logs a warning
	 *
	 * @param message The message to log
	 */
	warn: (message: string, ...args: unknown[]): void => {
		if (logger.loggerLoaded()) {
			logger.internalLogger.warn(prefixMessage(message), ...args);
			return;
		}
		if (process.env.NEXT_PUBLIC_ALLOW_BROWSER_CONSOLE === 'true') console.warn(prefixMessage(message), ...args);
	},

	/**
	 * Logs a debug message
	 *
	 * @param message The message to log
	 */
	debug: (message: string, ...args: unknown[]): void => {
		if (logger.loggerLoaded()) {
			logger.internalLogger.debug(prefixMessage(message), ...args);
			return;
		}
		if (process.env.NEXT_PUBLIC_ALLOW_BROWSER_CONSOLE === 'true') console.debug(prefixMessage(message));
	},

	/**
	 * Logs an error
	 *
	 * @param message The error message
	 */
	error: (message: string, ...args: unknown[]): void => {
		if (logger.loggerLoaded()) {
			logger.internalLogger.error(prefixMessage(message), ...args);
			return;
		}
		if (process.env.NEXT_PUBLIC_ALLOW_BROWSER_CONSOLE === 'true') console.error(prefixMessage(message), ...args);
	},

	/**
	 * Timer for durationMs property.
	 * `durationMs` is used in cases such as to
	 * show how long it
	 * takes to run/fetch data in our logging
	 * object
	 */
	startTimer: (): { done: (message: unknown) => void } => {
		if (logger.loggerLoaded()) {
			const profiler = logger.internalLogger.startTimer();
			return profiler;
		}
		const id = generateId(6);
		if (process.env.NEXT_PUBLIC_ALLOW_BROWSER_CONSOLE === 'true') console.time(id);
		return {
			done: (message: unknown) => {
				if (process.env.NEXT_PUBLIC_ALLOW_BROWSER_CONSOLE === 'true') {
					message['timer'] = id;
					console.info(message);
					console.timeEnd(id);
				}
			},
		};
	},
};

/** Lazy load winston.js to avoid big 1st load
 * together with Javascript */
if (!logger.loggerLoaded()) {
	import('./_loggerWinston').then((module) => {
		logger.setInternalLogger(module.default);
	});
}

export default logger;
