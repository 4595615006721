import React, { useContext } from 'react';
import {
	NavigationRepositoryFactory,
	NavigationRepositoryInterface,
} from 'application/repositories/navigationRepository';

const NavigationContext = React.createContext<Partial<NavigationRepositoryInterface>>({});

export interface NavigationContextProviderProps {
	children?: React.ReactNode;
	navigation: Navigation.NavigationItem;
}

export const NavigationContextProvider: React.FC<NavigationContextProviderProps> = (props) => {
	const navigationRepository = NavigationRepositoryFactory(props.navigation);

	return <NavigationContext.Provider value={navigationRepository}>{props.children}</NavigationContext.Provider>;
};

export function useNavigationContext(): Partial<NavigationRepositoryInterface> {
	return useContext(NavigationContext);
}
