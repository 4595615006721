// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const minimalError = (error) => {
	const minimalError = {
		minimalError: {
			name: error.name,
			message: error.message,
			stack: error.stack,
			response: {
				data: error.response?.data,
				status: error.response?.status,
			},
		},
	};

	return minimalError;
};
