import { getCmsNavigation } from 'application/adapters/cms/navigationAdapter';
import { getUrlPathname } from 'helpers/getUrlPathname/getUrlPathname';

export interface GetNavigationOptions {
	host: string;
	byName?: string;
	currentUrl?: string;
	currentSegmentPath: string;
	includeHidden?: boolean;
	includeRootItem?: boolean;
	includeSegmentRootPages?: boolean;
}
export interface NavigationRepositoryInterface {
	getNavigation: (options?: GetNavigationOptions) => Navigation.NavigationItem[];
	getBreadcrumb: (options?: GetNavigationOptions) => Navigation.NavigationItem[];
	getSegmentPages: (options?: GetNavigationOptions) => Navigation.NavigationItem[];
}

export const NavigationRepositoryFactory = (data: Navigation.NavigationItem): NavigationRepositoryInterface => {
	if (!data) return;

	const getSegmentedData = (data: Navigation.NavigationItem, options: GetNavigationOptions) => {
		const mappedChildren = data.children
			.map((item) => {
				if (item.documentType === 'segmentPage') {
					const itemPathname = getUrlPathname(item.url, options.host);
					return itemPathname.startsWith(options.currentSegmentPath) ? item.children : null;
				} else {
					return item;
				}
			})
			.filter((item) => item) // Remove null values
			.flat();

		return {
			...data,
			children: mappedChildren,
		};
	};

	const createNavigationItem = (
		navigation: Navigation.NavigationItem,
		options?: GetNavigationOptions,
	): Navigation.NavigationItem => {
		if (navigation.hidden && !options?.includeHidden) {
			return null;
		}

		const children = navigation.children
			?.map((item) => {
				return createNavigationItem(item, options);
			})
			?.filter((item) => item);

		const isCurrent = options?.currentUrl ? options?.currentUrl === navigation.url : false;
		const isActive = isCurrent || children?.find((item) => item.current || item.active) ? true : false;

		return {
			id: navigation.id,
			name: navigation.name,
			url: navigation.url,
			children: children?.length === 0 ? null : children,
			title: navigation.title ?? null,
			hidden: navigation.hidden ?? null,
			current: isCurrent,
			active: isActive,
		};
	};

	const getNavigationItems = (options?: GetNavigationOptions): Navigation.NavigationItem[] => {
		const navData = options?.includeSegmentRootPages ? data : getSegmentedData(data, options);
		const root = options?.byName ? findByName(options?.byName, navData) : navData;
		if (!root) return null;

		const itemsToReturn = options.includeRootItem ? [root] : root.children;
		return itemsToReturn?.map((item) => createNavigationItem(item, options))?.filter((item) => item);
	};

	return {
		// Return visible navigation items.
		// This is only done for the first level for now
		// and could be refined if the need arises.
		getNavigation(options): Navigation.NavigationItem[] {
			return getNavigationItems(options);
		},

		// Returns the bread crumb
		// For now just the root and the current item (if any)
		getBreadcrumb(options): Navigation.NavigationItem[] {
			const flattenItems = (items: Navigation.NavigationItem[]) => {
				const flattened = [];
				items.forEach((element) => {
					flattened.push(element);
					if (element.children) {
						const flattenedDesc = flattenItems(element.children);
						flattenedDesc.forEach((f) => flattened.push(f));
					}
				});
				return flattened;
			};
			const items = getNavigationItems(options);
			return flattenItems(items).filter((item) => item.active);
		},

		// Return segment pages
		getSegmentPages(options: GetNavigationOptions): Navigation.NavigationItem[] {
			const segmentPages = data.children.filter((item) => item.documentType === 'segmentPage');

			if (!segmentPages) return null;

			return segmentPages.map((item) => {
				const itemPathname = getUrlPathname(item.url, options.host);

				return {
					...createNavigationItem(item, options),
					isActive: itemPathname === options.currentSegmentPath,
				};
			});
		},
	};
};

export interface GetNavigationInterface {
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
}
// Return full set of nested navigation items.
// This is pure data for static props and must be serializable
// Preferably it should have been part of the repository factory function
// but objects cannot be passed as static props
export const getNavigationData = async ({
	host,
	preview,
	previewData,
}: GetNavigationInterface): Promise<Navigation.NavigationItem> => {
	const navigationResponse = await getCmsNavigation({
		host,
		preview,
		previewData,
	});

	return navigationResponse;
};

// Return navigation items by its name.
// This is only done for the first level of children for now
// and could be refined if the need arises.
const findByName = (name: string, item: Navigation.NavigationItem): Navigation.NavigationItem => {
	const normalizedName = name.toLowerCase();
	if (item.name === normalizedName) {
		return item;
	} else {
		const foundItem = item.children.filter((item) => {
			return item.name.toLowerCase() === normalizedName;
		});
		return foundItem.length
			? foundItem[0]
			: {
					id: 0,
					name: '',
					url: '',
			  };
	}
};
