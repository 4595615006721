/**
 *
 * @param theFunction
 * @returns True if theFunction is not undefined and is typeof 'function'.
 */
export const functionNotNull = <T>(theFunction?: T): boolean => {
	return theFunction !== undefined && typeof theFunction === 'function';
};

/**
 * Returns true, if param is not null or undefined.
 */
export const nullCheck = <T>(param: T): boolean => {
	return param !== null && param !== undefined;
};
